<script lang="ts" setup>
const { t } = useT();

const props = defineProps<{
	enabled?: boolean;
	finishedAt?: string;
}>();

const emit = defineEmits<{ (event: "click"): void }>();
const handleClick = () => {
	emit("click");
};
</script>

<template>
	<ACard
		class="season"
		width="584px"
		height="760px"
		:imgAttrs="{
			width: 584,
			height: 761,
			src: '/nuxt-img/seasons/independence-day/promo/bg.png',
			alt: 'tournament-promo-bg',
			format: 'avif',
			loading: 'lazy'
		}"
		alt="seasons"
		@click="handleClick"
	>
		<template #topLine>
			<MPromotionTimer :timeLeft="props?.finishedAt" />
		</template>
		<template #top>
			<MPrizeFund is-svg icon="entries" :icon-size="24" :offset="0.5" variant="entries">
				<AText variant="tirana" :modifiers="['bold']" class="text-shadow">{{ t("77,000") }}</AText>
				<AText :modifiers="['uppercase', 'semibold']" class="free text-shadow">{{ t("for free") }}</AText>
			</MPrizeFund>
		</template>
		<template #bottom>
			<NuxtImg
				src="/nuxt-img/seasons/independence-day/promo/logo.png"
				format="avif"
				width="584"
				height="606"
				alt="pot"
				loading="lazy"
				class="eagle"
			/>
			<AButton variant="primary" size="xl" class="foot" @click="handleClick">
				<AText variant="turin" :modifiers="['medium']">
					{{ t("Jump In") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.season {
	--a-card-default-top-gap: 8px;

	width: 292px;
	height: 380px;
}

.countdown {
	position: relative;
	padding: 8px;
	width: calc(100% - 24px);
	height: 34px;
	background: rgba(var(--chattogram-rgb), 0.4);
	margin: 0 auto;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	z-index: 3;
}

.eagle {
	position: absolute;
	bottom: 0;
	width: 292px;
	height: 294px;
}
.text-shadow {
	text-shadow:
		0 10px 15px rgba(var(--corlu-rgb), 0.1),
		0 4px 6px rgba(var(--corlu-rgb), 0.05);
}

.free {
	width: 35px;
	line-height: 16px;
}

.foot {
	width: 250px;
}
</style>
